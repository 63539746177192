/* eslint-disable */
import Velocity from 'velocity-animate';

export default class Collapsible {
  constructor(el, options) {
    this.el = el;
    this.contentNode = el.querySelector('[data-collapsible-content]');
    this.arrowNode = el.querySelector('[data-collapsible-arrow]');

    this.options = {
      openClass: 'open',
      duration: 400,
      initOpen: el.dataset.hasOwnProperty('collapsibleInitOpen'),
      ...options,
    };

    this.init();
  }

  init() {
    // Hide element
    if(this.options.initOpen) this.open();
    else this.close();

    // Add click listener
    this.el.querySelector('[data-collapsible-trigger]').addEventListener('click', () => { this.toggle() });
  }

  open() {
    if(this.options.onOpen) this.options.onOpen(this);

    const { duration, openClass } = this.options;

    Velocity(this.contentNode, 'slideDown', { duration });

    if(this.arrowNode) Velocity(this.arrowNode, { rotateZ: '-180deg' }, { duration });

    this.el.classList.add(openClass);
  }

  close() {
    const { duration, openClass } = this.options;

    Velocity(this.contentNode, 'slideUp', { duration });

    if(this.arrowNode) Velocity(this.arrowNode, { rotateZ: '0deg' }, { duration });

    this.el.classList.remove(openClass);
  }

  toggle() {
    if(this.isOpen()) this.close();
    else this.open();
  }

  isOpen() {
    return this.el.classList.contains(this.options.openClass);
  }
}

import Popper from 'popper.js';
import Velocity from 'velocity-animate';

const getContentElement = el => el.querySelector('[data-dropdown-content]');

const getTriggerElement = el => el.querySelector('[data-dropdown-trigger]');

const open = (el, { duration, openClass, placement }) => {
  const content = getContentElement(el);
  const trigger = getTriggerElement(el);

  content.style.opacity = 0;
  content.style.display = 'block';

  if(!content || !trigger) return;

  new Popper(trigger, content, {
    placement,
    onCreate() {
      Velocity(content, { opacity: 1 }, {
        duration,
        complete: function () {
          content.classList.add(openClass);
        },
      });
    },
  });

};

const close = (el, { duration, openClass }) => {
  const content = getContentElement(el);

  Velocity(content, 'fadeOut', {
    duration,
    complete: function() {
      el.classList.remove(openClass);
    },
  })
};

export default (el, opts = {}) => {
  const content = getContentElement(el);

  const options = {
    openClass: 'open',
    duration: 200,
    placement: 'bottom',
    ...opts,
  };

  content.style.display = 'none';

  el.addEventListener('mouseenter', () => open(el, options));

  el.addEventListener('mouseleave', () => close(el, options));
};
import qs from 'qs';

(() => {
  const OPEN_CLASS = 'rtc-navigation--search-open';
  const navigation = document.querySelector('.rtc-navigation');
  const togglers = document.querySelectorAll('*[data-toggle-search]');

  if(!navigation) return;

  const isOpenSearch = () => navigation.classList.contains(OPEN_CLASS);

  const toggleSearch = (focus = true) => {
    navigation.classList.toggle(OPEN_CLASS);

    if(isOpenSearch() && focus) {
      navigation.querySelector('input').focus();
    }
  }

  const inverseIcon = toggler => {
    const iconNode = toggler.querySelector('.rtc-icon');

    if(iconNode) {
      if(iconNode.classList.contains('rtc-search')) {
        iconNode.classList.remove('rtc-search');
        iconNode.classList.add('rtc-close');
      } else {
        iconNode.classList.remove('rtc-close');
        iconNode.classList.add('rtc-search');
      }
    }
  };

  for (let index = 0; index < togglers.length; index++) {
    const el = togglers[index];

    el.addEventListener('click', function () {
      const opts = el.dataset.toggleSearch || '';

      toggleSearch();

      if(opts.includes('inverse-icon')) {
        inverseIcon(el);
      }
    });
  }

  const queryParams = qs.parse(window.location.search.slice(1));

  if(queryParams.s)
    toggleSearch(false);

})();

/* eslint-disable */
import Velocity from 'velocity-animate';

const OPEN_CLASS = 'rtc-drawer-layout--open';

const content = document.querySelector('.rtc-drawer-layout__content');
const buttonTrigger = document.querySelector('[data-drawer-trigger]');
const drawer = document.querySelector('.rtc-drawer');
const layout = document.querySelector('.rtc-drawer-layout');

const isOpen = () => layout.classList.contains(OPEN_CLASS);

const open = () => {
  drawer.style.display = 'flex';
  layout.classList.add(OPEN_CLASS);


  Velocity(content, {
    position: 'fixed',
    translateY: '86px',
    translateX: window.innerWidth < 500 ? '320px' : '400px',
  });
};

const close = () => {
  Velocity(content, {
    translateY: '0px',
    translateX: '0px'
  }, {
    complete () {
      layout.classList.remove(OPEN_CLASS);
      content.style.transform = 'none';
      drawer.style.display = 'none';
    },
  });
};

if(buttonTrigger)
  buttonTrigger.addEventListener('click', e => {
    open();

    e.stopPropagation();
  });

if(content)
  content.addEventListener('click', e => {
    if(isOpen()) {
      e.preventDefault();
      close();
    }
  });



(() => {
  const newsletterBox = document.querySelector('.rtc-footer__newsletter');

  if(!newsletterBox) return;

  const COUNTRY_CODE = newsletterBox.dataset.countryCode;
  const SAURON_URL = newsletterBox.dataset.sauronUrl;
  const STORAGE_KEY = `${COUNTRY_CODE}_newsletter_suscribed`;

  const newsletterForm = newsletterBox.querySelector('form');
  const newsletterFormInput = newsletterBox.querySelector('input');
  const newsletterFormButton = newsletterBox.querySelector('button');

  const onSuccess = () => {
    newsletterBox.classList.add('rtc-footer__newsletter--success');

    localStorage.setItem(STORAGE_KEY, true);
  };

  const addRequestingState = state => {
    newsletterFormInput.setAttribute('disabled', state);
    newsletterFormButton.setAttribute('disabled', state)
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const email = newsletterFormInput.value.trim().toLowerCase();

    addRequestingState(true);

    fetch(SAURON_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          mutation AddEmailToMailingList {
            addEmailToMailingList(data: { address: "${email}", topic: "blog" }) {
              status
              errors { message argument }
            }
          }
        `,
      }),
    }).then(() => {
      onSuccess();
    }).finally(() => {
      addRequestingState(false);
    })
  }

  if(localStorage.getItem(STORAGE_KEY))
    onSuccess();
  else
    newsletterForm.addEventListener('submit', onSubmit);
})();

// import external dependencies
import 'rtc-firebase-lib';
import 'rtc-social-share';

// Import everything from autoload


/** Populate Router instance with DOM routes */

// Components
import './components/collapsibles.js'; import './components/drawer.js'; import './components/dropdown.js'; import './components/header.js'; import './components/newsletter.js'; import './components/search.js';

if(document.getElementById('rtc-social-share-buttons'))
  window.rtcSocialShare.mount(document.getElementById('rtc-social-share-buttons'));